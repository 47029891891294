<template>
  <div style="font-size: 18px;" class="main">
    <Form ref="addForm" :model="addFormData" :rules="addFormOrgan" :label-width="90" :label-colon="true">
      <FormItem label="组织名称" prop="name">
        <Input :maxlength="20" v-model.trim="addFormData.name"></Input>
      </FormItem>
      <FormItem label="排序">
        <InputNumber :min="1" :max='999999999' v-model="addFormData.seq"></InputNumber>
      </FormItem>
    </Form>
  </div>
</template>

<script lang="js">


export default {
  name: "LiefengorganizationModel",
  components: {},
  props: {
    addFormData: Object,
    addFormOrgan: {
      type: Object,
    },
    value: {
      type: Boolean
    },
    toggle: {
      type: Boolean
    },
  },
  data() {
    return {}
  },
  methods: {
    save(data) {
      this.$refs.addForm.validate(status => {
        console.log(data, '拿到')
        if (status) {
          this.$post('/gx/pc/organization/updateOrganization', {
            dimensionId: data.dimensionId,
            parentId: 0,
            orgId:data.orgId
          }).then(res => {
            if (res.code == 200) {
              console.log(res)
              this.$emit('clickFn',true)
            } else {
              this.$emit('clickFn',false)
              this.$Message.warning({
                content: res.desc,
                background: true
              });
            }
          })
        } else {
          return
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>
