<template>
  <LiefengContentManage>
    <template v-slot:title>组织机构管理</template>
    <template v-slot:toolsbarLeft>
      <div style="margin-left: 20px">
        组织维度
        <Select @on-change="changeFn" v-model="model1Select" style="width:200px">
          <Option v-for="(item,index) in dimensionNameArr" :value="item" :key="index">{{ item }}</Option>
        </Select>
      </div>
    </template>
    <template v-slot:toolsbarRight>
      <Input
          :maxlength="20"
          placeholder='组织名称或编码搜索'
          v-model.trim="selectName"
          style="width: 200px; margin-right: 5px"
          @on-search="search"
      ></Input>
      <Button type="primary" @click="search">搜索</Button>
      <Button type="success" @click="reset">重置</Button>
      <Button type="default">维度管理</Button>
      <Button type="default" @click="organNewsFn">新增组织</Button>
    </template>
    <template v-slot:contentArea>
      <div class="container">
        <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageSize"
            @hadlePageSize="hadlePageSize"
            @tableSelect="tableSelect"
        ></LiefengTable>
        <LiefengModal
            :fullscreen="false"
            :title="modalTitle" :value="addStatus" @input="addStatusFn"
        >
          <template v-slot:contentarea>
            <!--        修改-->
            <LiefengorganizationModel
                ref="modelValue1"
                :value="addStatus"
                :addFormOrgan="addFormOrgan"
                :addFormData="addFormData"
                v-if="addStatus"
                @clickFn="upDataPageSize"
            >
            </LiefengorganizationModel>
            <!--            新增组织-->

          </template>
          <template v-slot:toolsbar>
            <Button type="info" style="margin-right: 10px" @click="addStatusFn(false)">取消</Button>
            <Button type="primary" @click="addModalSave">保存</Button>
          </template>
        </LiefengModal>
      </div>
    </template>
  </LiefengContentManage>
</template>

<script>
//@route('/organization')
import LiefengTable from "@/components/LiefengTable";
import LiefengContentManage from "@/views/childmanagement/LiefengContentManage";
import LiefengorganizationModel from "@/views/organizationmanagement/LiefengorganizationModel";
import LiefengModal from "@/components/LiefengModal";


export default {
  components: {
    LiefengTable,
    LiefengContentManage,
    LiefengorganizationModel,
    LiefengModal
  },
  data() {
    return {
      //修改弹窗
      value: false,
      //验证表单
      addFormOrgan: {
        name: [
          {required: true, message: "请输入组织名称", trigger: "blur"}
        ],
      },
      addFormData: {
        seq: 1,
        name: '',
      },
      modalTitle: '',
      addStatus: false,
      organizationList: {},
      //end

      //查看属性名
      dimenNameList: [],
      //用于搜索的组织维度id
      dimenNewsId: '',

      //字典搜索
      dimensionIdList: {},

      //组织维度选择
      dimensionNameArr: [],
      //end

      //选择框
      model1Select: '',
      //end

      tableSelectArr: [], //table选中的
      //table列表
      tableColumns: [
        {
          title: '序号',
          type: 'index',
          minWidth: 100,
          align: "center",
        },
        {
          title: '组织名称',
          key: 'orgName',
          align: "center",
          minWidth: 200
        },
        {
          title: '编码',
          key: 'orgCode',
          align: "center",
          minWidth: 200
        },
        {
          title: '组织属性',
          key: 'orgType',
          minWidth: 250,
          align: "center",
          render:(h,params)=>{
            return h('div',[
                h('span',{},
                  params.row.orgType==='1'?'组织机构':
                   params.row.orgType==='2'?'部门':''
                )
            ])
          }
        },
        {
          title: '所属组织维度',
          key: 'dimensionName',
          minWidth: 250,
          align: "center",
        },
        {
          title: '创建时间',
          key: 'gmtCreate',
          minWidth: 155,
          align: "center",
        },
        {
          title: '排序',
          key: 'seq',
          minWidth: 80,
          align: "center",
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          align: "center",
          minWidth: 300,
          render: (h, params) => {
            let data=params.row
            return h('div', [
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                style: {
                  marginRight: '6px'
                },
                on: {
                  click: () => {
                    this.deleteFn(data)
                  }
                }
              }, '删除'),
              h('Button', {
                props: {
                  type: 'success',
                  size: 'small'
                },
                style: {
                  marginRight: '6px'
                },
                on:{
                  click:()=>{
                    this.modify(data)
                  }
                }
              }, '修改'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '6px'
                }
              }, '授权系统'),
              h('Button', {
                props: {
                  type: 'info',
                  size: 'small'
                }
              }, '服务范围授权'),
            ]);
          }
        }
      ],
      tableData: [], //table的数据
      loading: false, //table 加载
      page: 1, //分页
      total: 0,   //总数量
      pageSize: 20, //每一页显示的数量
      selectName: "", // 搜索名称或编码
    };
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize
    });
  },
  beforeMount() {
    this.tableHeight = window.innerHeight - 200 + '';
  },
  methods: {
    //新增组织
    organNewsFn() {
      console.log('22')

    },

    //刷新列表
    upDataPageSize(status) {
      if (status === true) {
        this.addStatus = false
        this.hadlePageSize({
          page: this.page,
          pageSize: this.pageSize
        });
      } else {
        this.addStatus = status
      }

    },
    //删除
    deleteFn(data) {
      console.log(data)
      this.$Modal.confirm({
        title: "温馨提示",
        content: `<p>是否确定<span style="color: red;margin: 0 2px;">删除</span>操作</p>`,
        cancelText: "取消",
        okText: "确认",
        onOk: () => {
          this.$get("/gx/pc/organization/delete", {
            orgId:data.orgId
          }).then(res => {
            if (res.code == 200) {
              this.$Message.success({
                content: "删除成功",
                background: true
              });
              this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize
              });
            } else {
              this.$Message.error({
                background: true,
                content: res.desc
              });
            }
          })
              .catch(err => {
                this.loading = false;
                this.$Message.error({
                  background: true,
                  content: "获取数据失败"
                });
              });
        }
      });
    },
    //修改
    modify(data) {
      console.log(data, '修改')
      //修改该项的数据
      this.organizationList = data;
      this.modalTitle = '修改';
      this.addStatus = true;
      //详情回显
      this.$get('/gx/pc/organization/selectByOrgId', {
        orgId: data.orgId
      }).then(res => {
        if (res.code == 200 && res.data) {
          console.log(res)
          this.addFormData = {
            seq: res.data.seq || 1,
            name: res.data.orgName,
          }
        } else {
          this.$Message.warning({
            content: res.desc,
            background: true
          });
        }
      })
    },
    //取消修改弹窗
    addStatusFn(status) {
      this.addStatus = status
    },
    //修改保存
    addModalSave() {
      this.$refs.modelValue1.save(this.organizationList)
    },
    //end

    //组织维度
    changeFn(val) {
      console.log(val, '组织维度')
      this.dimenNameList.forEach(item => {
        if (item === val) { //拿组织维度对应的维度id
          this.dimenNewsId = this.dimensionIdList[val]
          console.log(this.dimenNewsId, 'id')
        }
      })
    },
    tableSelect(data) { //表格选中
      this.tableSelectArr = JSON.parse(JSON.stringify(data));
      console.log(data)
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/gx/pc/organization/selectOrganizationPage", {
        dimensionId: this.dimenNewsId || '',
        orgName: this.selectName || '',
        orgCode: this.selectName || '',
        page: obj.page,
        pageSize: obj.pageSize,
      }).then(res => {
        this.loading = false;
        if (res.code == 200 && res.dataList) {
          res.dataList.map((item, index) => {
            item.gmtCreate = this.$core.formatDate(
                new Date(item.gmtCreate),
                "yyyy-MM-dd hh:mm:ss"
            );
          });


          this.tableData = res.dataList;
          this.page = res.currentPage;
          this.pageSize = res.pageSize;
          this.total = res.maxCount;

          let dimensionNameList = []

          res.dataList.map(item => {
            dimensionNameList.push(item.dimensionName)
          })
          //过滤组织维度
          this.dimensionNameArr = dimensionNameList.filter((item, index, self) => {
            return self.indexOf(item) === index
          })

          this.dimensionNameArr.unshift('全部')
          console.log(this.dimensionNameArr, 'dimensionNameArr')

          res.dataList.forEach(item=>{    //字典 /组织维度
            this.dimensionNameArr.forEach(i=>{
              if(item.dimensionName===i){
                console.log(i,'ii')
                this.dimensionIdList[i]=item.dimensionId
                console.log(item.dimensionId,'888')
                return;
              }
            })
          })
                console.log(this.dimensionIdList,'dimensionIdList')
          this.dimenNameList=Object.keys(this.dimensionIdList)
          console.log(this.dimenNameList,'查看属性名')

        } else {
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      })
          .catch(err => {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: "获取数据失败"
            });
          });

    },
    //搜索
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    //重置
    reset() {
      this.selectName = "";
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
  },
};
</script>

<style scoped lang='less'>
.container {
  height: calc(100vh - 85px);
  width: calc(100vw - 20px);
}

</style>

